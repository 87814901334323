import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Initiativepage from 'components/initiative/initiativepage';


// markup
const Initiativelist = () => {
    return (
      <>
    <SEO title='Forests By Heartfulness | Initiative list' />
      <Layout>
     <section id='iniativelistitem'>
         <Initiativepage />
       </section>
      </Layout>
  </>
);
};

export default Initiativelist;